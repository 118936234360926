<script>
import TablePage from '@/found/components/table_page';
import Form from '../form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    treeCode: String,
  },
  data() {
    return {
      params: {
        directId: '',
      },
    };
  },
  watch: {
    treeCode(val) {
      this.params.directId = val;
      this.getList();
    },
  },
  async created() {
    await this.getConfigList('supermarket_deport_list');
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'add') {
        // 新增
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '新增商超系统';
        this.modalConfig.width = '50%';
        this.openModal();
      } else if (val.code === 'edit') {
        // 编辑
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑商超系统';
        this.modalConfig.width = '50%';
        this.openModal();
      }
    },
  },
};
</script>
