<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('supermarket_deport_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.get('/kms/kmsdirectstore/query', { id: this.formConfig.id }).then((res) => {
        this.setValue({
          ...res.result,
        });
        this.row = res.result;
      });
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'areaId') {
        // 所属大区
        item.restful = '/kms/kmsdirectstorearea/list';
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'dsAreaName',
        };
        item.optionsKey = { label: 'dsAreaName', value: 'id' };
        item.restfulParamsGetValue = { directId: 'directId' };
      } else if (item.field === 'directId') {
        // 商超系统
        item.restful = '/kms/kmsdirectsystem/list';
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'directName',
        };
        item.optionsKey = { label: 'directName', value: 'id' };
      }
      return item;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...this.row,
        ...formData,
      };
      request.post(this.formConfig.id ? ('/kms/kmsdirectstore/update') : ('/kms/kmsdirectstore/save'), param).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
